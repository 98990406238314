import React, { useState, useEffect } from 'react';
import {
  DataSheetGrid,
  intColumn,
  textColumn,
  keyColumn,
} from 'react-datasheet-grid'

// Import the style only once in your app!
import 'react-datasheet-grid/dist/style.css'
import './App.css';

const testFrame = '34 00 00 00 00 00 00 00 00 17 f7 1c 93 94 01 30 00 39 31 33 64 37 66 62 34 38 62 61 36 37 66 39 64 01 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00'
const testDef = [
  {
    "name": "帧码",
    "datatype": "BIN",
    "length": 1
  },
  {
    "name": "交易流水号",
    "datatype": "BCD   码",
    "length": 16
  },
  {
    "name": "桩编号",
    "datatype": "BCD   码",
    "length": 16
  },
  {
    "name": "枪号",
    "datatype": "BCD   码",
    "length": 1
  },
  {
    "name": "逻辑卡号",
    "datatype": "BCD   码",
    "length": 8
  },
  {
    "name": "物理卡号",
    "datatype": "BIN   码",
    "length": 8
  },
  {
    "name": "账户余额",
    "datatype": "BIN   码",
    "length": 4
  },
  {
    "name": "预留",
    "datatype": "BIN   码",
    "length": 8
  }
]

function App() {
  const [input, setInput] = useState(testFrame);
  const [bytes, setBytes] = useState([]);
  const [frameDef, setFrameDef] = useState(testDef)
  const [byteGroups, setByteGroups] = useState([]);
  const [frameDefName, setFrameDefName] = useState('');
  const [savedFrameDefs, setSavedFrameDefs] = useState(Object.keys(localStorage));
  const [matchedFrameDefs, setMatchedFrameDefs] = useState([]);
  const [nonMatchedFrameDefs, setNonMatchedFrameDefs] = useState([]);
  const [selectedFrameDefName, setSelectedFrameDefName] = useState('');
  const [base64Input, setBase64Input] = useState('');
  const [base64Changed, setBase64Changed] = useState(false);
  const [bytesChanged, setBytesChanged] = useState(false);

  const columns = [
    {
      ...keyColumn('name', textColumn),
      title: '参数名称',
      maxWidth: 160
    },
    {
      ...keyColumn('datatype', textColumn),
      title: '数据类型',
      maxWidth: 100
    },
    {
      ...keyColumn('length', intColumn),
      title: '长度',
      maxWidth: 60
    },
    {
      ...keyColumn('bytes', textColumn),
      title: '对应字节',
      disabled: true
    },
    {
      ...keyColumn('decoded', textColumn),
      title: '解析内容',
      disabled: true
    },
  ]


  const handleInputChange = (event) => {
    setInput(event.target.value);
    setBytesChanged(true);
    parseBytes(event.target.value);
  };

  const handleBase64InputChange = (event) => {
    setBase64Input(event.target.value);
    setBase64Changed(true);
  };

  const parseBytes = (input) => {
    const parsedBytes = input.split(/[\s\r\n]+/).map((byte, index) => ({ byte, index }));
    return parsedBytes;
  };

  useEffect(() => {
    const parseBytes = (input) => {
      const parsedBytes = input.split(' ').map((byte, index) => ({ byte, index }));
      return parsedBytes;
    };

    const parsedBytes = parseBytes(input);
    setBytes(parsedBytes);

    let newByteGroups = [];
    let lengthSum = 0;
    for (let i = 0; i < frameDef.length; i++) {
      lengthSum += frameDef[i].length;
      newByteGroups.push(parsedBytes.slice(lengthSum - frameDef[i].length, lengthSum));
    }

    setByteGroups(newByteGroups);
  }, [input, frameDef]);

  useEffect(() => {
    const allFrameDefs = Object.keys(localStorage);
    const matched = [];
    const nonMatched = [];
  
    allFrameDefs.forEach(name => {
      const loadedFrameDef = JSON.parse(localStorage.getItem(name));
      const totalLength = loadedFrameDef.reduce((sum, def) => sum + def.length, 0);
      const frameDefInfo = { name, totalLength };
  
      if (totalLength === bytes.length) {
        matched.push(frameDefInfo);
      } else {
        nonMatched.push(frameDefInfo);
      }
    });
  
    matched.sort((a, b) => a.totalLength - b.totalLength || a.name.localeCompare(b.name));
    nonMatched.sort((a, b) => a.totalLength - b.totalLength || a.name.localeCompare(b.name));
  
    setMatchedFrameDefs(matched.map(info => info.name));
    setNonMatchedFrameDefs(nonMatched.map(info => info.name));
  }, [bytes.length]);

  const getBackgroundColor = (index) => {
    let lengthSum = 0;
    for (let i = 0; i < frameDef.length; i++) {
      lengthSum += frameDef[i].length;
      if (index < lengthSum) {
        return i % 2 === 0 ? '#f0f0f0' : '#ffffff';
      }
    }
    return '#f0f0f0';
  };

  const deleteFrameDef = (name) => {
    localStorage.removeItem(name);
    setSavedFrameDefs(Object.keys(localStorage));
  }
  const saveFrameDef = () => {
    localStorage.setItem(frameDefName, JSON.stringify(frameDef));
    setSavedFrameDefs(Object.keys(localStorage));
  }

  const loadFrameDef = (name) => {
    const loadedFrameDef = JSON.parse(localStorage.getItem(name));
    setFrameDef(loadedFrameDef);
    setSelectedFrameDefName(name);
  }

  const decodeBytes = (datatype, byteGroup) => {
    let decoded = '';
    const trimmedDatatype = datatype?.trim().toLowerCase();
    if (trimmedDatatype?.includes('bin')) {
      decoded = parseInt(byteGroup?.map(b => b.byte).join(' '), 16);
    } else if (trimmedDatatype?.includes('ascii')) {
      decoded = byteGroup?.map(b => String.fromCharCode(parseInt(b.byte, 16))).join('');
    }
    // Add more conditions here as needed
    return decoded;
  };

  const exportFrameDefs = () => {
    const data = savedFrameDefs.reduce((acc, name) => {
      const frameDef = JSON.parse(localStorage.getItem(name));
      const cleanedFrameDef = frameDef.map(({ bytes, decoded, ...rest }) => rest);
      acc[name] = cleanedFrameDef;
      return acc;
    }, {});

    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "frameDefs.json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  const importFrameDefs = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop();
      if (fileExtension !== 'json') {
        alert('Please upload a .json file');
        return;
      }

      const reader = new FileReader();
      reader.onload = function (e) {
        try {
          // Validate the JSON
          const data = JSON.parse(e.target.result);
          for (const name in data) {
            localStorage.setItem(name, JSON.stringify(data[name]));
          }
          setSavedFrameDefs(Object.keys(localStorage));
        }
        catch (error) {
          alert('Invalid JSON file');
        }
      };
      reader.readAsText(file);
    }
  }

  const renderFrameDefCard = (name, isMatched) => {
    const loadedFrameDef = JSON.parse(localStorage.getItem(name));
    const totalLength = loadedFrameDef.reduce((sum, def) => sum + def.length, 0);

    return (
      <div
        className="savedFrameDef-card"
        style={{
          borderColor: isMatched ? 'blue' : 'gray',
          boxShadow: isMatched ? '0px 0px 5px blue' : '0px 0px 5px gray',
          background: name === selectedFrameDefName ? 'linear-gradient(to right, #e0eafc, #cfdef3)' : 'white',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ color: isMatched ? 'blue' : 'gray', marginRight: '10px' }}>{totalLength}</div>
          <div onClick={() => loadFrameDef(name)}>{name}</div>
        </div>
        <div
          className="delete-button"
          onClick={() => deleteFrameDef(name)}
        >
          &#10006;
        </div>
      </div>
    );
  };



  const decodeBase64 = () => {
    const trimmedInput = base64Input.trim();
    if (trimmedInput) {
      try {
        const bytes = atob(trimmedInput)
          .split('')
          .map(c => c.charCodeAt(0).toString(16).padStart(2, '0'))
          .join(' ');
        setInput(bytes);
        setBase64Changed(false);
        setBytesChanged(false);
      } catch (error) {
        alert('Invalid Base64 input');
      }
    }
  };
  
  const encodeToBase64 = () => {
    const base64 = btoa(input.replace(/\s/g, '').match(/.{1,2}/g).map(b => String.fromCharCode(parseInt(b, 16))).join(''));
    setBase64Input(base64);
    setBase64Changed(false);
    setBytesChanged(false);
  };

  return (
    <div className="App">
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, margin: '20px' }}>
          <body className="App-header">
          <textarea type="text" value={base64Input} onChange={handleBase64InputChange}
              placeholder='请输入Base64编码的文本'
              style={{
                width: '100%',
                minHeight: '3em',
                padding: '10px',
                fontSize: '16px',
                borderRadius: '4px',
                border: base64Changed ? '1px solid #bc47f0' : '1px solid #ccc',
                boxShadow: base64Changed ? '0px 0px 5px #bc47f0' : 'none',
              }}
            />
            <button onClick={decodeBase64} title="Base64解码" style={{ margin: '6px', fontSize: '20px' }}>&#8595;</button>
            <button onClick={encodeToBase64} title="Base64编码" style={{ margin: '6px', fontSize: '20px' }}>&#8593;</button>
            <textarea type="text" value={input} onChange={handleInputChange}
              placeholder='请输入帧内容'
              style={{
                width: '100%',
                minHeight: '3em',
                padding: '10px',
                fontSize: '16px',
                borderRadius: '4px',
                border: bytesChanged ? '1px solid #bc47f0' : '1px solid #ccc',
                boxShadow: bytesChanged ? '0px 0px 5px #bc47f0' : 'none',
              }}
            />
            <div style={{
              textAlign: 'right', color: savedFrameDefs.some(name => {
                const loadedFrameDef = JSON.parse(localStorage.getItem(name));
                const totalLength = loadedFrameDef.reduce((sum, def) => sum + def.length, 0);
                return totalLength === bytes.length;
              }) ? 'blue' : 'gray'
            }}>{bytes.length} bytes</div>

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '20px' }}>
              {bytes.map(({ byte, index }) => (
                <div key={index} style={{ margin: '4px', padding: '10px', borderRadius: '5px', backgroundColor: getBackgroundColor(index) }}>
                  <span style={{ fontSize: '20px' }}>{byte}</span>
                  <div style={{ fontSize: '12px', color: 'gray', userSelect: 'none' }}>{index + 1}</div>
                </div>
              ))}
            </div>

            {selectedFrameDefName ? <h3>已选择: {selectedFrameDefName}</h3> : null}
            <DataSheetGrid
              value={frameDef.map((def, index) => {
                const decoded = decodeBytes(def.datatype, byteGroups[index]);
                return { ...def, bytes: byteGroups[index]?.map(b => b.byte).join(' '), decoded };
              })}
              onChange={setFrameDef}
              columns={columns}
            />


            <div>
              <input type="text" value={frameDefName} onChange={(e) => setFrameDefName(e.target.value)} placeholder="请输入名称" />
              <button onClick={saveFrameDef}>保存帧定义</button>
            </div>
          </body>
        </div>
        <div style={{ width: '300px', marginLeft: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: '#fff' }}>
          <h3>Matched by length</h3>
          {matchedFrameDefs.map((name) => renderFrameDefCard(name, true))}

          <h3>Others</h3>
          {nonMatchedFrameDefs.map((name) => renderFrameDefCard(name, false))}

          <button onClick={exportFrameDefs}>导出</button>

          <input type="file" id="import" hidden onChange={importFrameDefs} />
          <label htmlFor="import" style={{ cursor: 'pointer' }}>导入</label>
        </div>
      </div>
    </div>
  );
}

export default App;